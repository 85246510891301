// src/components/Sidebar.js

import React, { useState } from 'react';
import './Sidebar.css'; // Crea un archivo CSS para estilos personalizados

function Sidebar({ onSelect, signOut }) {
    const [active, setActive] = useState(''); // Estado para saber cuál botón está activo

    const handleClick = (section) => {
        setActive(section);
        onSelect(section);
    };

    return (
        <div className="sidebar">
            <img src="/dentsu.png" className='dentsu-logo' />

            <button
                className={`sidebar-button ${active === 'campaigns' ? 'active' : ''}`}
                onClick={() => handleClick('campaigns')}
            >
                Lista de bancos
            </button>
            <button
                className={`sidebar-button ${active === 'upload' ? 'active' : ''}`}
                onClick={() => handleClick('upload')}
            >
                Cargar archivos
            </button>

            <button
                className={`sidebar-button close-section`}
                onClick={signOut}
            >
                Cerrar sesión
            </button>

            <img src="/mc.png" className='mc-logo' />
            
            {/* Puedes añadir más botones aquí si tienes más secciones */}
        </div>
    );
}

export default Sidebar;

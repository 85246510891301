import React, { useEffect, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import './CampaignList.css'; // Estilos adicionales
import { LIST } from "../constants/constants"

import axios from 'axios';
import { saveAs } from 'file-saver';

function CampaignList() {
    const [files, setFiles] = useState([]); // Inicializa 'files' como un array vacío
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Indicador de carga
    const [orgs, setOrgs] = useState(false);
    const [orgIndex, setOrgIndex] = useState(false);

    useEffect(() => {
      console.log("Cambiuo elk uindex:", orgIndex)
        const fetchFiles = async () => {
            try {
                setLoading(true); // Mostrar el indicador de carga
                const authSession = await fetchAuthSession();
                const idToken = authSession.tokens?.idToken?.toString();

                // Extraer los grupos del token
                const groups = authSession.tokens.idToken.payload['cognito:groups'];
                console.log("All seccion:", authSession.tokens.idToken.payload)
                console.log("GBrupos!", groups)
                setOrgs(groups)
                
                if (!groups || groups.length === 0) {
                    throw new Error("El usuario no pertenece a ningún grupo");
                }

                if (orgIndex || orgIndex === 0) {
                  // Usar el primer grupo como identificador de la organización (org_id)
                  const org_id = groups[orgIndex];
                  
                  console.log('Org ID extraído del grupo:', org_id); // Verificar que se extraiga correctamente

                  const response = await fetch(LIST, {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({ org_id }), // Enviar 'org_id' obtenido del token
                      mode: 'cors'
                  });

                  console.log('Código de respuesta:', response.status); // Imprimir el código de respuesta
                  const data = await response.json();
                  console.log('Datos obtenidos:', data); // Imprimir la respuesta obtenida
                  const parsedBody = JSON.parse(data.body);
                  // console.log('Archivos:', parsedBody.files); // Imprimir los archivos obtenidos

                  const list = []
                  parsedBody.files.forEach(file => {
                    if (file.file_name.slice(-1) !== '/') {
                      const fileName = file.file_name.split('/')
                      console.log("File name:", fileName)

                      const fileInfo = {
                        download_url: file.download_url,
                        file_name: fileName[fileName.length - 1],
                        campain: fileName[0]
                      }

                      list.push(fileInfo)
                    }
                  })

                  setFiles(list || []); // Asegúrate de que 'files' sea un array
                }

                setLoading(false); // Desactivar el indicador de carga
            } catch (error) {
                setLoading(false); // Desactivar el indicador de carga
                setError(error.message);
                console.error("Error al listar campañas: ", error);
            }
        };
        
        fetchFiles();
    }, [orgIndex]);

    if (loading) {
        return <div className="loading">
          <img src="/logo_mc.gif" className='loader' />
        </div>;
    }

    if (error) {
        return <div className="error">Error: {error}</div>;
    }

    const handleOrg = (e) => {
      setOrgIndex(
        Number(e.target.getAttribute('data-index'))
      )
    };

    const downloadFile = async (downloadUrl, filename) => {
        try {
            const response = await axios.get(downloadUrl, {
                responseType: 'blob',
            });
            saveAs(response.data, filename);
            console.log('Archivo descargado con éxito');
        } catch (error) {
            console.error('Error al descargar el archivo', error);
        }
    };

    console.log("files", files)

    return (
        <div className="campaign-list">

            <div className='org-section'>
                <h2>Bancos</h2>
                <table className="files-table">
                  <thead>
                      <tr>
                          <th>Nombre</th>
                          <th className='action-width'>Acción</th>
                      </tr>
                  </thead>
                  <tbody>
                      {orgs.length > 0 ? (
                          orgs.map((org, index) => (
                              <tr key={org}>
                                  <td>{org}</td>
                                  <td className='action-width'>
                                      <button data-index={index} onClick={handleOrg} className='action-button'>Listar archivos</button>
                                  </td>
                              </tr>
                          ))
                      ) : (
                          <tr>
                              <td colSpan="2">No hay archivos disponibles.</td>
                          </tr>
                      )}
                  </tbody>
              </table>

            </div>
            

            {(orgIndex || orgIndex === 0)
                ? <>
                    <h2>Lista de archivos - {orgs[orgIndex]}</h2>
                    <table className="files-table">
                        <thead>
                            <tr>
                                <th>Nombre del archivo</th>
                                <th className='action-width'>Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.length > 0 ? (
                                files.map((file, index) => (
                                    <tr key={index}>
                                        <td><strong>{file.campain}</strong> - {file.file_name}</td>
                                        <td className='action-width'>
                                            <button 
                                                className='action-button'
                                                onClick={() => downloadFile(file.download_url, file.file_name)}
                                            >
                                                Descargar
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="2">No hay archivos disponibles.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                
                  </>
                : <p>Seleccione una organización para ver sus archivos.</p>
            }
        </div>
    );
}

export default CampaignList;

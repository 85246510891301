// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client'; // Cambiar importación para usar createRoot
import App from './App';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

// Crear un contenedor raíz
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

// Renderizar la aplicación usando createRoot
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

import React, { useState, useEffect } from 'react';
import './FileUploader.css'; // Estilos adicionales
import shortid from "https://cdn.skypack.dev/shortid@2.2.16";
import { fetchAuthSession } from 'aws-amplify/auth';
import { UPLOAD } from "../constants/constants"

function FileUploader() {
  const [selectedfile, SetSelectedFile] = useState('');
  const [Files, SetFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [banks, setBanks] = useState(false);
  const [activeBank, setActiveBank] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const authSession = await fetchAuthSession();
        const idToken = authSession.tokens?.idToken?.toString();
        const groups = authSession.tokens.idToken.payload['cognito:groups'];
        setBanks(groups)

      } catch (error) {
        alert("Error al listar los bancos")
        console.error("Error al obtener los bancos: ", error);
      }
    };
        
    fetchFiles();
  }, []);

  const filesizes = (bytes, decimals = 2) => {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const InputChange = (e) => {
      // --For Single File Input
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
          SetSelectedFile({
              id: shortid.generate(),
              filename: e.target.files[0].name,
              filetype: e.target.files[0].type,
              fileimage: reader.result,
              datetime: e.target.files[0].lastModifiedDate.toLocaleString('en-IN'),
              filesize: filesizes(e.target.files[0].size)
          });
      }
      if (e.target.files[0]) {
          reader.readAsDataURL(file);
      }
  }

  const DeleteSelectFile = (id) => {
      if(window.confirm("Are you sure you want to delete this Image?")){
          const result = selectedfile.filter((data) => data.id !== id);
          SetSelectedFile(result);
      }else{
          // alert('No');
      }
      
  }

  const FileUploadSubmit = async (e) => {
      e.preventDefault();

      // form reset on submit 
      e.target.reset();

      if (selectedfile !== '' && activeBank) {
        setUploading(true)
        const authSession = await fetchAuthSession();
        const idToken = authSession.tokens?.idToken?.toString();
        let file = selectedfile.fileimage.split(',')[1]
        // file = selectedfile.fileimage

        const body = {
          "campaign": activeBank,
          "file_name": selectedfile.filename,
          "file_content": file
        }

        console.log("FIle", selectedfile)
        console.log("Body", JSON.stringify(body))

        const response = await fetch(UPLOAD, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
            mode: 'no-cors'
        });
        console.log('Código de respuesta:', response);

        if (response) {
          SetFiles((preValue)=>{
            return[
                ...preValue,
                selectedfile
            ]   
          })
          SetSelectedFile('');
          setUploading(false)
          setActiveBank(false)

        } else {
          alert("Error al cargar el archivo")
        }

        
      } else {
          alert('No ha seleccionado un archivo o un banco.')
      }
  }

  const DeleteFile = async (id) => {
      if(window.confirm("Are you sure you want to delete this Image?")){
          const result = Files.filter((data)=>data.id !== id);
          SetFiles(result);
      }else{
          // alert('No');
      }
  }

  const selectBank = async (e) => {
    setActiveBank(e.target.getAttribute('data-bank'))
  }

    return (
        <div className="campaign-list">
          <h2>Cargar un nuevo archivo</h2>

          <div>
            <div className="fileupload-view">
                <div className="row justify-content-center m-0">
                    <div className="col-md-6">
                        <div className="card mt-5">
                            <div className="card-body">
                                <div className="kb-data-box">
                                    <form onSubmit={FileUploadSubmit}>
                                        <div className="kb-file-upload">
                                            <div className="file-upload-box">
                                                <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} />
                                                <span>Arrastra o <span className="file-link">Selecciona un archivo</span></span>
                                            </div>
                                        </div>
                                        <div className="kb-attach-box mb-3 listoOffiles">
                                          {selectedfile !== '' ?
                                            <div className="file-atc-box">
                                                {
                                                    selectedfile.filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                        <div className="file-image"> <img src={selectedfile.fileimage} alt="" /></div> :
                                                        <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                }
                                                <div className="file-detail">
                                                    <h6>{selectedfile.filename}</h6>
                                                    <p><span>Size : {selectedfile.filesize}</span><span className="ml-2">Modified Time : {selectedfile.datetime}</span></p>
                                                </div>
                                            </div>
                                            : ''}
                                        </div>

                                        <div className='bank-list'>
                                          <h5>Elija un banco</h5>
                                          

                                          {banks
                                            ? <ul>
                                                {banks.map((bank, index) => {
                                                  return (
                                                    <li 
                                                      key={index} 
                                                      className={`${activeBank === bank ? 'active' : ''}`} 
                                                      data-bank={bank}
                                                      onClick={selectBank}
                                                    >{bank}</li>
                                                  )
                                                })}
                                              </ul>
                                            : <div className="loading">
                                                <img src="/logo_mc.gif" className='loader' />
                                              </div>
                                          }


                                        </div>


                                        <div className="kb-buttons-box">
                                            <button type="submit" className="btn btn-primary form-submit">Cargar</button>
                                        </div>
                                    </form>

                                    {uploading &&
                                      <div className="loading">
                                        <img src="/logo_mc.gif" className='loader' />
                                      </div>
                                    }


                                    {Files.length > 0 ?
                                        <div className="kb-attach-box">
                                            <h5>Archvos cargados correctamente. Para cargar más archivos seleccione el nuevo archivo en la parte de arriba y el banco de destino.</h5>

                                            <hr />
                                            {
                                                Files.map((data, index) => {
                                                    const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                    return (
                                                        <div className="file-atc-box" key={index}>
                                                            {
                                                                filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                    <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                    <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                            }
                                                            <div className="file-detail">
                                                                <h6>{filename}</h6>
                                                                <p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>


        </div>
    );
}

export default FileUploader;

// src/App.js

import React, { useState } from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import {
  Authenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
  useAuthenticator,
  Button
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';

import Sidebar from './components/Sidebar';  // Importar el nuevo componente Sidebar
import CampaignList from './components/CampaignList';  // Importar el nuevo componente CampaignList
import FileUploader from './components/FileUploader';  // Importar el nuevo componente FileUploader

Amplify.configure(awsExports);



const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <img src="/dentsu-login.png" className='dentsu-login' />
        <img src="/mc-login.jpg" className='mc-login' />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          Dentsu 2024 - &copy; Todos los derechos reservados
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
         Bienvenido/a
        </Heading>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
          >
            ¿Olvidó su contraseña?
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Registro
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Volver a inicio
          </Button>
        </View>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  SetupTotp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ForgotPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
    },
  },
  signUp: {
    password: {
      label: 'Contraseña:',
      placeholder: 'Ingresar contraseña:',
      isRequired: false,
      order: 2,
    },
    confirm_password: {
      label: 'Confirmar contraseña:',
      order: 1,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: 'Ingresar contraseña:',
    },
  },
  forgotPassword: {
    username: {
      placeholder: 'Ingresar correo electrónico:',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Ingresar código de confirmación:',
      label: 'New Label',
      isRequired: false,
    },
    confirm_password: {
      placeholder: 'Ingresar contraseña por favor:',
    },
  },
  setupTotp: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user',
    },
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Ingresar código de confirmación:',
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Ingresar código de confirmación:',
      isRequired: false,
    },
  },
};


function App() {
  const [section, setSection] = useState('');

  return (
    <div className="App">
      <Authenticator components={components}>
        {({ signOut, user }) => (
          <div className="app-container">
            <Sidebar onSelect={setSection} signOut={signOut} />  {/* Añadir Sidebar */}
            <div className="content">
              <h1>Bienvenido, {user.signInDetails.loginId}</h1>

              {section === '' && <p className='starterInfo'>Para empezar, usar el menú lateral izquierdo para navegar entre secciones.</p>}

              {section === 'campaigns' && <CampaignList />}  {/* Mostrar CampaignList si la sección seleccionada es "campaigns" */}
              {section === 'upload' && <FileUploader />}  {/* Mostrar FileUploader si la sección seleccionada es "upload" */}
              
              <div className='close-section-app'>
                <button 
                  onClick={signOut} 
                  style={{ 
                    margin: '20px', 
                    fontSize: '0.8rem', 
                    padding: '5px 10px', 
                    marginTop: '20px'
                  }}
                >
                  Cerrar sesión
                </button>
              </div>
            </div>
          </div>
        )}
      </Authenticator>
    </div>
  );
}

export default App;
